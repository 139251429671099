
import store from "@/store/index";
import { WorkareaController, DepartmentController } from '@/controller';
import { Button, IconButton, SelectSingle } from '@/ui/index';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "WorkareaareaForm",
    components: { Button, IconButton, SelectSingle },
    data() {
        return {
            store,
            editMode: false,
            form:{
                uid: 0,
                title: "",
                department: {
                    uid: 0,
                    title: ""
                },
            },
            departments: [] as Array<any>
        }
    },
    computed:{
        getProfileImageSrc() {
            return "";
        }
    },
    created() {
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.editMode = true;
            this.handleGetWorkarea(this.$route.params.id);
        } else {
            this.editMode = false;
        }
        this.handleGetDepartments();
    },
    methods: {
        handleGoBack() {
            this.$router.go(-1);
        },
        onDepartmentChanged(item : any, value : number, text: string){
            this.form.department = {
                uid: value,
                title: text
            }
        },
        handleSubmitPrevent() {
            if (this.editMode) {
                this.handleSave();
            } else {
                this.handleCreate();
            }
        },
        async handleGetWorkarea(uid:any) {
            const res = await WorkareaController.getWorkareaById(uid);
            if (!res.error) {
                this.form = res.workarea;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleGetDepartments() {
            const res = await DepartmentController.fetchDepartments();
            if(!res.error){
                this.departments = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleCreate() {
            const res = await WorkareaController.createWorkarea(this.form, this.form.department.uid);
            if (!res.error) {
                this.$notify(this.$t("success.workareaCreated"), { position: "top" });
                this.$router.push("/administration/workareas");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleSave() {
            const res = await WorkareaController.updateWorkarea(this.form, this.form.department.uid);
            if (!res.error) {
                this.form = res.workarea;
                this.$notify(this.$t("success.workareaUpdated"), { position: "top" });
                this.$router.push("/administration/workareas");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
